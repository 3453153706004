// noinspection JSUnresolvedVariable,TypeScriptUMDGlobal

if(!window.history){
    window.history = {};
}
if(!window.history.pushState){
    window.history.pushState = function(){};
}

window.message = {
    defaultOptions: {
        "closeButton": true,
        "debug": false,
        "progressBar": true,
        "preventDuplicates": false,
        "positionClass": "toast-top-center",
        "onclick": null,
        "showDuration": "400",
        "hideDuration": "1000",
        "timeOut": "7000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    },
    error: function(message){
        if(message) {
            toastr.options = this.defaultOptions;
            toastr.error(message);
        }
    },
    success: function(message){
        if(message) {
            toastr.options = this.defaultOptions;
            toastr.success(message);
        }
    }
};

window.modalWindow = function(path){
    $.fancybox($.extend({}, CONFIG.FANCYBOX, {
        type: 'ajax',
        href: path,
        ajax: {
            type: 'get'
        },
        wrapCSS: "styled-fancybox overflow-visible",
        helpers: {
            overlay: {
                closeClick: false
            }
        }
    }));
};

//region parse query string and return as object
window.getParams = function() {
    const url = new URL(window.location.href);

    let params = [];
    for (const [key, value] of url.searchParams) {
        if (!params[key]) {
            params[key] = value;

            if (value.indexOf(',') >= 0 && key.indexOf('[]') >= 0) {
                params[key] = value.split(',');
            }
        }
    }

    return params;
};
//endregion

window.number_format = function(number, decimals, dec_point, thousands_sep) {
    // Format a number with grouped thousands
    //
    // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
    // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +	 bugfix by: Michael White (http://crestidg.com)

    let decimalsF, i, j, kw, kd, km;
    let dec_pointF = dec_point;
    let thousands_sepF = thousands_sep;
    let numberF = number;

    decimalsF = Math.abs(decimals);
    // input sanitation & defaults
    if( isNaN(decimalsF) ){
        decimalsF = 2;
    }
    if( dec_point === undefined ){
        dec_pointF = ",";
    }
    if( thousands_sep === undefined ){
        thousands_sepF = " ";
    }
    numberF = (+numberF || 0).toFixed(decimalsF);
    i = parseInt(numberF) + "";

    if( (j = i.length) > 3 ){
        j %= 3;
    } else{
        j = 0;
    }

    km = (j ? i.substring(0, j) + thousands_sepF : "");
    kw = i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands_sepF);
    kd = (decimalsF ? dec_pointF + Math.abs(numberF - i).toFixed(decimalsF).replace(/-/, 0).slice(2) : "");

    return km + kw + kd;
};

window.plurals = function(number, one, two, five) {
    let numberF = number;
    const twenty = 20;
    let result = five;
    numberF = Math.abs(numberF);
    numberF %= 100;
    if (numberF >= 5 && numberF <= twenty) {
        result = five;
    } else {
        numberF %= 10;
        if (numberF === 1) {
            result = one;
        } else {
            if (numberF >= 2 && numberF <= 4) {
                result = two;
            }
        }
    }
    return result;
};

window.repeatOrder = function(params){
    let button = $(this);
    if (!button.hasClass('disabled')) {
        button.addClass('disabled');

        swal({
            title: CONFIG.LANG['continue?'],
            text: params.repeatConfirmText,
            type: "info",
            showCancelButton: true,
            confirmButtonColor: "#18A689",
            cancelButtonText: CONFIG.LANG['buttonCancel'],
            confirmButtonText: CONFIG.LANG['buttonProceed'],
            closeOnConfirm: false,
            html: true,
            showLoaderOnConfirm: true
        }, function (isConfirm) {
            if(isConfirm) {
                $.post(button.attr('href'), {}, function (data) {
                    if (data.message) {
                        if(data.success){
                            swal(data.success, data.message, "success");
                        } else if (data.cartCount) {
                            swal({
                                title: params.cartIsNotEmpty,
                                text: data.message,
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#18A689",
                                cancelButtonColor: "red",
                                cancelButtonText: CONFIG.LANG['delete'],
                                confirmButtonText: CONFIG.LANG['retain'],
                                closeOnConfirm: false,
                                html: true,
                                closeOnCancel: false,
                                showLoaderOnConfirm: true
                            }, function (isConfirm) {

                                let params = {};

                                if(isConfirm){
                                    params.clearCart = 0;
                                } else {
                                    params.clearCart = 1;
                                }

                                $.post(button.attr('href'), params, function(data){
                                    if(data.message) {
                                        if(data.success){
                                            swal(data.success, data.message, "success");
                                        }
                                        if(data.redirect) {
                                            setTimeout(function () {
                                                location.href = data.redirect;
                                            }, 1000);
                                        }
                                    } else {
                                        message.error(CONFIG.LANG.unknown_error);
                                    }
                                }, 'json').fail(function(response){
                                    showResponseError(response);
                                });
                            });
                        }
                        if (data.redirect) {
                            setTimeout(function () {
                                location.href = data.redirect;
                            }, 1000);
                        }
                    } else {
                        button.removeClass('disabled');
                        message.error(CONFIG.LANG.unknown_error);
                    }
                }, 'json').fail(function (response) {
                    button.removeClass('disabled');
                    showResponseError(response);
                });
            } else {
                button.removeClass('disabled');
            }
        });
    }
};

window.localStorageSupport = function() {
    return (('localStorage' in window) && window['localStorage'] !== null)
};

window.setPhoneMaskByCountry = function ($phoneInput, countryId) {
    const inputMasksByCountries = {
        21: '+375(99)999-99-99', // BY
        170: '+48 999-999-999',  // PL
        175: '+7(999)999-99-99', // RU
        221: '+38(099)999-99-99' // UA
    };

    const intercityCodeSU = 8;
    const phoneSULength = 11;
    const countryCodeRu = 175;
    const countryCodeBy = 21;

    let mask = inputMasksByCountries[countryId];

    const phoneNumber = $phoneInput.val();

    if (
        typeof phoneNumber === 'string'
        && parseInt(phoneNumber.charAt(0)) === intercityCodeSU
        && phoneNumber.length === phoneSULength
    ) {
        // междугородний формат звонков СССР

        if (parseInt(countryId) === countryCodeRu) {
            $phoneInput.val( phoneNumber.substring(1) )
        }
        if (parseInt(countryId) === countryCodeBy) {
            $phoneInput.val( phoneNumber.substring(2) )
        }
    }

    if (mask) {
        $phoneInput
            .mask(mask, {autoclear: false})
            .on('click', function() {
                let el = $(this);
                if (!el.hasClass('js_input_click')) {
                    let length = el.val().replace(/_+[-| )]|_+/g, '').length;
                    el.addClass('js_input_click');
                    if (el[0].setSelectionRange === undefined) {
                        // noinspection JSUnresolvedFunction
                        let range = el[0].createTextRange();
                        range.collapse(true);
                        // noinspection JSUnresolvedFunction
                        range.moveEnd('character', length);
                        // noinspection JSUnresolvedFunction
                        range.moveStart('character', length);
                        range.select();
                    } else {
                        el[0].setSelectionRange(length, length);
                    }
                }
            })
            .on('keyup touchend', function () {
                if(/\+7\([78]9/.test($(this).val())) {
                    let $originalVal = $(this).val();
                    let $newVal = $originalVal.replace(/\+7\([78]9/gi,'79');

                    $(this).val($newVal).trigger('input');
                }
            })
            .on('blur', function() {
                $(this).removeClass('js_input_click');
            });
    } else {
        $phoneInput.unmask().off('click');
    }
};

window.showResponseError = (response) => {
    /**
     * @property {Object} response
     * @property {Object} response.responseJSON
     * @property {Number} response.status
     */
    if (typeof response.responseJSON !== 'undefined') {
        message.error(response.responseJSON.message);
    } else if (response.status !== 0) {
        message.error(CONFIG.LANG.unknown_error + ' ' + response.status);
    }
};

window.SHIFTPRESS = false;

window.initChosenIfNotInited = function($select, params){
    let paramsF = params || {};
    if($select.length && $select.is(':visible')){
        let id = $select.attr('id');
        if(!$("#"+id+"_chosen").length){
            $select.chosen($.extend({}, CONFIG.CHOSEN, paramsF));
        }
    }
};

$(initializeLongFunctions);
document.addEventListener('turbo:render', initializeLongFunctions);

window.initMainSlider = function (){
    const $slider = $('#slidersGrid');
    const time = 4000;
    const $bar = $('.slider-progress .progress');
    let isPause,
        tick,
        timeStart,
        percentTime,
        slideCount = 0;

    $slider.on('init reInit', function(event, slick){
        slideCount = slick.slideCount;
        isPause = slick.slideCount <= 1;
    });

    $slider.slick({
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        adaptiveHeight: true,
    });

    $slider.on('mouseenter mouseleave', function(e) {
        if (e.originalEvent.type === 'mouseover') {
            $(this).addClass('play');
            isPause = true;
            resetProgressbar();
            $bar.css({
                width: '100%'
            });
        } else if(slideCount > 1) {
            $(this).removeClass('play');
            isPause = false;
            startProgressbar();
        }
    });

    const startProgressbar = function() {
        resetProgressbar();
        percentTime = 0;
        isPause = false;
        timeStart = Date.now();
        tick = setInterval(interval, 10);
    };

    const interval = function() {
        if ($slider.hasClass('play')) {
            isPause = true;
        }
        if (isPause === false) {
            let currentTime = Date.now() - timeStart;
            percentTime = 100 * currentTime / time;
            if (percentTime >= 100) {
                percentTime = 100;
                $slider.slick('slickNext');
                startProgressbar();
            }
            $bar.css({
                width: percentTime + "%"
            });
        }
    };

    const resetProgressbar = function() {
        $bar.css({
            width: '0%'
        });
        clearTimeout(tick);
        timeStart = 0;
    };

    startProgressbar();

    $slider.on('beforeChange', function() {
        resetProgressbar();
        startProgressbar();
        $bar.css({
            width: '100%'
        });
    });
};

// noinspection FunctionTooLongJS
function initializeLongFunctions() {
    var $mainMenu = $("#mainMenu");
    $mainMenu.on('click', '.with-submenu', function (e) {
        if ($(e.target).closest('a').length === 0) {
            $(this).toggleClass('active');
            e.stopPropagation();
        }
    });

    $("body").on('click', function (e) {
        $('#mainMenu .with-submenu').removeClass('active');
    });

    $(".menu-active").find('a').each(function(){
        if($(this).attr('href') === location.pathname){
            var $li = $(this).parents('li');
        if(!$li.hasClass('no-active'))
            $li.addClass('active');
        }
    });

    $('[data-moment]').autoMoment();
    setInterval(function(){
        $('[data-moment]').autoMoment();
    }, 20000);

    var $body = $('body');

    $body.on('click', '.fancybox-ajax', function(e){
        e.preventDefault();
        $.fancybox($.extend({}, CONFIG.FANCYBOX, {
            type: 'ajax',
            href: $(this).attr('href'),
            ajax: {
                type: 'get'
            },
            wrapCSS: $(this).data('fancyboxClass') || 'styled-fancybox overflow-visible'
        }));
    }).on('click', '.open-modal', function(e){
        e.preventDefault();
        modalWindow($(this).attr('href'));
    });

    var scrollFromUrl = location.search.match(/scroll_to=(.*?)$|&/);
    if(scrollFromUrl){
        var $target = $("#"+scrollFromUrl[1]);
        if($target.length){
            setTimeout(function(){
                $(window).scrollTo($target, 200, {offset: {top: -70}});
            }, 1500);
        }
    }

    $body.on('click',"[data-switcher-name]",function(e){

        var name = $(this).data("switcherName"),
            value = $(this).data("switcherValue"),
            parent = $("#switch_" + name);

        if(value) {

            e.preventDefault();

            parent.parent()
                .find('[data-switcher-text]').text($(this).text()).end()
                .find("input[data-switcher-name=" + name + "]").val(value);

            $("[data-switcher-name=" + name + "][data-switcher-input]").hide()
                .filter("[data-switcher-input=" + value + "]").show(0, function () {
                var chosenSelect = $(this).hasClass('chosen') ? $(this) : $(this).children('.chosen');
                if (chosenSelect.length) {
                    chosenSelect.chosen(CONFIG.CHOSEN);
                    if (chosenSelect.data('showCallback')) {
                        window[chosenSelect.data('showCallback')]();
                    }
                }
            }).change();
        }
    });

    var $topButtonSide = $("#topButtonSide");
    var $catalogTopPanel = $("#catalogTopPanel");
    var $header = $("#header");
    $(window).on('scroll', function(){
        if($(window).scrollTop() === 0){
            $header.removeClass('fixed');
            $catalogTopPanel.removeClass('fixed');
        } else {
            if($catalogTopPanel.length){
                $catalogTopPanel.addClass('fixed');
                $header.addClass('catalog-view');
            }
            $header.addClass('fixed');
        }
        if($(window).scrollTop() > 300){
            $topButtonSide.fadeIn(600);
        } else {
            $topButtonSide.fadeOut(600);
        }
    });
    $(window).trigger('scroll');

    $topButtonSide.on('click', function () {
        $('html, body').scrollTo(0, 400, {easing:'swing'});
    });

    //region ловим зажатый shift
    $body.on('keydown', function (e) {
        if(e.keyCode === 16){
            SHIFTPRESS = true;
        }
    }).on('keyup', function (e) {
        if(e.keyCode === 16){
            SHIFTPRESS = false;
        }
    });
    //endregion

    $('.js-image-switcher').on('click', function (e) {
        e.preventDefault();
        var state = $(this).attr('data-state') === 'true',
            newState = !state;
        if (state) {
            $('.js-images-switcher__target').show();
        } else {
            $('.js-images-switcher__target').hide();
        }
        $(this).attr('data-state', newState);
        $.cookie('images_switcher_state', newState, {path: '/', expires: 365, domain: '.' + CONFIG.URL['domain']});
    });

    // Кнопка аккордеона раскрывает своё содержимое (но не закрывает всё остальное)
    $('.accordion-open-button').on('click', function (e) {
        e.preventDefault();
        $(this)
            .toggleClass('right')
            .toggleClass('down');
        $(this).next().toggleClass('hidden');
        $(`[data-breadcrumbs="${this.dataset.category}"]`).toggleClass('hidden');
    });

    $('.js-footer-toggle-button').on('click', function(){
        var $footer = $(this).closest('.footer');
        $footer.toggleClass('opened');
        $.cookie('hide_footer', !$footer.hasClass('opened')?1:0, {path: '/', expires: 365, domain: '.' + CONFIG.URL['domain']});
    });

    $body.on('click', '#filters .ibox-title', function (e) {
        if ($(e.target).closest('.ibox-tools, .filter_tags_item, .reset-filters-button').length === 0) {
            $(this).find('.collapse-link').trigger("click");
        }
    });

    //region что бы картинка не уходила за экран
    const $catalogTable = $("#catalogTable");
    const openPhotosTimeOut = 550;
    let openPhotosTimer;
    $body
        .on('mouseenter', '.open-photos', function () {
            openPhotosTimer = setTimeout(() => {
                let catalogTableTop = 0;
                if ($catalogTable.length > 0) {
                    catalogTableTop = $catalogTable[0].getBoundingClientRect().top;
                }
                const $img = $(this).closest('.open-photos').find('.hovered-image');
                let topElement = $img[0].getBoundingClientRect().top;
                if (catalogTableTop > 0) {
                    topElement -= catalogTableTop;
                }
                if (topElement < 0) {
                    $img.css('bottom', topElement);
                }
            }, openPhotosTimeOut);
        })
        .on('mouseleave', '.open-photos', function () {
            if (openPhotosTimer) {
                clearTimeout(openPhotosTimer);
            }
            let $img = $(this).closest('.open-photos').find('.hovered-image');
            $img.removeAttr('style');
        });
    //endregion
}

$.fn.myTable = function(){
    var $table = $(this);
    return {
        getSelected: function(){
            var ids = [];
            $table.find('.icheckbox_square-green.checked').each(function(){
                ids.push($(this).parents('tr').attr('id'));
            });
            return ids;
        }
    };
};

$.fn.autoMoment = function(){
    $(this).each(function () {
        var $el = $(this),
            types = ['default','from-now'],
            dateStr = $el.data('moment'),
            format = $el.data('format'),
            type = $el.data('momentType') ? $el.data('momentType') : 'default';

        if(format){
            $el.text(moment(dateStr).format(format));
        } else {
            if(type === 'from-now'){
                $el.text(moment(dateStr).fromNow());
            }
            else {
                $el.text(moment(dateStr).format('LLL'));
            }
        }
    });
    return $(this);
};

/**
 * эта штука вызывается на select-ах роли в редактировании пользователей
 * при изменениях в селекте она отображает/скрывает элементы на основании аттрибута data-show-roles
 * @return {void|jQuery|HTMLElement}
 */
$.fn.roleChange = function(){
    $(this).each(function () {
        var $roleSelect = $(this);
        $roleSelect.chosen($.extend({}, CONFIG.CHOSEN, {
            allow_single_deselect: false
        }))
            .on('change', function(){
                var selectedRole = $(this).val();

                $('[data-show-roles]').each(function(){
                    var $el = $(this),
                        showForRoles = $el.data('showRoles');

                    if($.inArray(selectedRole, showForRoles) !== -1){
                        $el.show();
                        initChosenIfNotInited($el.find('select'));
                    } else {
                        $el.hide();
                    }
                });

                $('[data-hide-roles]').each(function(){
                    var $el = $(this),
                        hideForRoles = $el.data('hideRoles');

                    if($.inArray(selectedRole, hideForRoles) !== -1){
                        $el.hide();
                    } else {
                        $el.show();
                        initChosenIfNotInited($el.find('select'));
                    }
                });

                if (selectedRole === 'ROLE_SENIOR_MANAGER') {
                    $('.wrap_manager_block').removeClass('disabled');
                }

                if (selectedRole === 'ROLE_MANAGER') {
                    if ($('#switchModeAgent').prop('checked')) {
                        $('.wrap_manager_block').addClass('disabled');
                        $('#switchModeAgent').closest('.wrap_manager_block').removeClass('disabled');
                    }
                }

                $('[data-select]').trigger('change');
                $('[data-parent-switch-mode]').changeModeManager();
            }).trigger('change');
    });
    return $(this);
};

/**
 * эта штука разруливает выборы свяанных селектов, заполнение дочерних селектов, скрытие-показ нужных блоков.
 * общая идея в автоматизации и унификации: все разруливается через data-аттрибуты и данные в массиве selectsData, пришедшем с бекенда.
 * по идее, никакого кода для конкретных полей быть здесь не должно.
 * работает так:
 *  при выборе в селекте с data-select='store' (дальше буду называть его "parent")
 *  ищутся элементы с data-parent-select='store' (дальше буду называть его "child")
 *  внутри них скрываются элементы .js-hide-on-change если в parent-селекте выбрано значение, иначе - показываются
 *  и обратное поведение для элементов .js-show-on-change
 *  дальше в child-блоке ищем select. если находим - заполняем его данными из входного массива params.selectsData['store']
 * @return {void|jQuery|HTMLElement}
 */
$.fn.relatedSelects = function(options){
    var default_options = {
            selectsData: {}
        },
        params;

    params = $.extend(true, {}, default_options, options);
    var autoTrigger = false;

    $(this).each(function () {
        var $parentBox = $(this);
        $parentBox.on('change', '[data-select]', function(e){
            var $select = $(this),
                value = $select.val(),
                $parent = $select.closest('[data-show-roles]'),
                name = $select.data('select'), //за имя поля принимается значение его аттрибута data-select
                $childBoxes = $('[data-parent-select="'+name+'"]'); //по этому "имени" ищем зависимые элементы

            $.each($childBoxes, function(){
                var $childBox = $(this);
                $childBox.find('.js-hide-on-change')[value?'hide':'show']();
                $childBox.find('.js-show-on-change')[value?'show':'hide']();
                var type = $childBox.data('type');

                //в массиве selectsData под ключом-именем текущего элемента мы ожидаем данные для дочернего селекта.
                //если их нет - дочерний селект очищаем, скрываем и показывавем надпись "нет менеджеров для склада" или что-то вроде
                //+ лайфхак для менеджеров по закупкам, у которых склады ищутся от дистра в другую сторону (не дочерние а родительский)
                var items = [];
                var selectedRole = $('#roleSelect').val();

                if ((selectedRole === 'ROLE_MANAGER' || selectedRole === 'ROLE_SENIOR_MANAGER') && type) {
                    selectedRole = 'FOR_PURCHASE_MANAGER';
                    if (value && params.selectsData[selectedRole] && params.selectsData[selectedRole][name] && params.selectsData[selectedRole][name][type]) {
                        if (name === 'storeAgent') {
                            items = params.selectsData[selectedRole][name][type][value];

                            if (items[0]) {
                                $('#storeAgentName').text(items[0].name);
                            }
                        } else {
                            var buyStore = params.selectsData[selectedRole][name]['buyStore'][value];

                            if (buyStore[0]) {
                                $('#buyStoreName').text(buyStore[0].name);
                                $('#buyStoreBlock').find('.show-default').show();
                                $('#buyStoreBlock').find('.show-on-empty').hide();
                                items = params.selectsData[selectedRole][name][type] ?
                                    params.selectsData[selectedRole][name][type][buyStore[0].id] : params.selectsData[name][value];
                            } else {
                                $('#buyStoreBlock').find('.show-default').hide();
                                $('#buyStoreBlock').find('.show-on-empty').show();
                            }
                        }
                    }
                } else {
                    items = params.selectsData[selectedRole] && params.selectsData[selectedRole][name] ?
                        params.selectsData[selectedRole][name][value] :
                        (params.selectsData[name] ? params.selectsData[name][value] : []);
                }

                var options = '',
                    $childSelect = $childBox.find('select'),
                    selectedValue = $childSelect.data('value') || -1;

                if(!autoTrigger || !$childSelect.val()){
                    if (items && items.length) {

                        options = '<option value=""></option>';

                        for (var i = 0; i < items.length; i++) {
                            var item = items[i],
                                selected = selectedValue == item.id ? ' selected' : '';

                            options += '<option value="' + item.id + '"' + selected + '>' + (item.name.indexOf('</') + 1 ? item.name : item.name.replace('<', '&lt;').replace('>', '&gt;')) + '</opion>';
                        }

                        $childBox.find('.show-default').show();
                        $childBox.find('.show-on-empty').hide();

                    }

                    $childSelect
                        .html(options)
                        .trigger('chosen:updated')
                        .trigger('change');

                    $childBox.find('.show-default')[options ? 'show' : 'hide']();
                    $childBox.find('.show-on-empty')[options ? 'hide' : 'show']();
                    initChosenIfNotInited($childSelect);
                }
            });

            if(value){
                $parent.find('.js-hide-on-change').hide();
                $parent.find('.js-show-on-change').show();
            }
        });

        var $selectedOption = $('[data-select] option:selected');
        if($selectedOption.length){
            autoTrigger = true;
            $selectedOption.parent().trigger('change');
            setTimeout(function () {
                autoTrigger = false;
            }, 100);
        }
    });
    return $(this);
};

$.fn.changeModeManager = function () {
    $(this).each(function () {
        var $parent = $(this).closest('.wrap_manager_block');
        if (!$parent.hasClass('disabled')) {
            var $el = $('[data-switch="' + $(this).data('parent-switch-mode') + '"]');
            if ($(this).prop('checked')) {
                $el.show();
                initChosenIfNotInited($el.find('select'));
            } else {
                $el.hide();
            }
        } else {
            $(this).prop('checked', false);
        }
    })
};
$.fn.changeModeAgent = function () {
    $(this).each(function () {
        var $el = $('[data-switch="' + $(this).data('parent-switch-mode') + '"]');

        var $switchModePurchaseManager = $('[data-parent-switch-mode="switchModePurchaseManager"]');
        var $modePurchaseManager = $('[data-switch="switchModePurchaseManager"]');
        var $parentSwitchModePurchaseManager = $switchModePurchaseManager.closest('.wrap_manager_block');

        var $switchModeManager = $('[data-parent-switch-mode="switchModeManager"]');
        var $modeManager = $('[data-switch="switchModeManager"]');
        var $parentSwitchModeManager = $switchModeManager.closest('.wrap_manager_block');

        if ($(this).prop('checked')) {
            $el.show();
            initChosenIfNotInited($el.find('select'));

            $switchModePurchaseManager.prop('checked', false);
            $modePurchaseManager.hide();
            $parentSwitchModePurchaseManager.addClass('disabled');

            $switchModeManager.prop('checked', false);
            $modeManager.hide();
            $parentSwitchModeManager.closest('.wrap_manager_block').addClass('disabled');
        } else {
            $el.hide();

            $parentSwitchModePurchaseManager.removeClass('disabled');
            $parentSwitchModeManager.closest('.wrap_manager_block').removeClass('disabled');
        }
    })
};
$.fn.maskDate = function (options = {}) {
    $(this).mask('99.99.9999', {
        completed: function() {
            let date = $(this).val();
            if (!moment(date, 'DD.MM.YYYY').isValid()) {
                $(this).val('').blur().focus();
            }
            let min = CONFIG.LANG.min_date_project;
            if (options.min) {
                min = options.min;
            }
            const month = 11;
            const day = 31;
            let max = moment().add(1, 'years').month(month).date(day);
            if (options.max) {
                max = options.max;
            }
            console.log(max);
            if (moment(date, 'DD.MM.YYYY').isBefore(min)) {
                $(this).val(moment(min, 'YYYY-MM-DD').format('DD.MM.YYYY'));
            }
            if (moment(date, 'DD.MM.YYYY').isAfter(max)) {
                $(this).val(moment(max, 'YYYY-MM-DD').format('DD.MM.YYYY'));
            }
        }
    });
};
